import { useEffect } from "react";

const TripAdvisorWidget = () => {
  useEffect(() => {
    // Dynamically load the script when the component mounts
    const script = document.createElement("script");
    script.src = "https://www.jscache.com/wejs?wtype=certificateOfExcellence&uniq=274&locationId=13984881&lang=en_IN&year=2024&display_version=2";
    script.async = true;
    script.onload = () => {
      script.setAttribute("data-loadtrk", true);
    };
    document.body.appendChild(script);

    // Cleanup the script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="TA_certificateOfExcellence274"
      className="TA_certificateOfExcellence tripadvisor-widget"
    >
      <ul id="A80qIcMu5" className="TA_links rKPtuvDK3b">
        <li id="6Nq6YAzbu" className="5D6VL3ruuj4X">
          <a
            aria-label="Tripadvisor Widget"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.tripadvisor.in/Hotel_Review-g777776-d13984881-Reviews-The_Twin_Towers_Hotel-Kufri_Shimla_District_Himachal_Pradesh.html"
          >
            <img
              src="https://static.tacdn.com/img2/travelers_choice/widgets/tchotel_2024_LL.png"
              alt="TripAdvisor"
              className="widCOEImg"
              id="CDSWIDCOELOGO"
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default TripAdvisorWidget;
